<div class="poleTrainingContainer">
  <div class="poleJumbotron">
    <div>
      <app-nav></app-nav>
    </div>
    <div class="jumbotronDetails">
      <h1>Se Former, L'étape Indispensable</h1>
      <div class="jumbotronParagraph">
        <div>
          <p>
            Le but de cette formation est de pouvoir assimiler les différents aspects de l’entrepreneuriat,
            Notamment les particularités
            et les nuances dans les domaines juridiques, financiers,
            digitaux et commerciaux auxquels tout entrepreneur
            fait face en début de cycle de création.
          </p>
        </div>
        <div>
          <img src="/assets/partner-logos/datadock.png" alt="Certification" >
        </div>
      </div>
    </div>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1366 82"><defs><style>.cls-1{fill:#F6F6FA;}</style></defs><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><path class="cls-1" d="M0,0V82H1366V0A3734.36,3734.36,0,0,1,679,63,3734.22,3734.22,0,0,1,0,0Z"/></g></g></svg>
  </div>
</div>
<div class="trainingContainer">
  <h3>À Propos De Notre formation</h3>
  <div class="paragraphDescription">
    <p>Parfaitement en accord avec la réglementation, Direct du droit,
      dans son soucis d'accompagnement complet,
      propose ses formations dans plusieurs domaines.
    </p>
  </div>
  <div class="trainingTypeContainer">
    <div fxLayout.gt-sm="row" fxLayout.lt-md="column" fxLayoutAlign="space-between stretch">
      <!-- Training Type 1 -->
      <div>
        <div class="iconContainer mat-elevation-z4">
          <img src="/assets/icons/digital-marketing.png" alt="Remplir Le Formulaire">
        </div>
        <h4>Formation 1</h4>
        <div>
          <p>Vivamus fermentum vehicula elit, nec egestas sem interdum aliquam. Aenean malesuada nisi at nibh molestie,</p>
        </div>
      </div>
      <!-- Separator -->
      <div fxHide.lt-md class="pointsSeparatorContainer">
        <div style="text-align: center">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
      <!-- Training Type 2 -->
      <div>
        <div class="iconContainer mat-elevation-z4">
          <img src="/assets/icons/digital-marketing.png" alt="Remplir Le Formulaire">
        </div>
        <h4>Formation 2</h4>
        <div>
          <p>Vivamus fermentum vehicula elit, nec egestas sem interdum aliquam. Aenean malesuada nisi at nibh molestie,</p>
        </div>
      </div>
      <!-- Separator -->
      <div fxHide.lt-md class="pointsSeparatorContainer">
        <div style="text-align:center">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
      <!-- Training Type 3 -->
      <div>
        <div class="iconContainer mat-elevation-z4">
          <img src="/assets/icons/digital-marketing.png" alt="Remplir Le Formulaire">
        </div>
        <h4>Formation 3</h4>
        <div>
          <p>Vivamus fermentum vehicula elit, nec egestas sem interdum aliquam. Aenean malesuada nisi at nibh molestie,</p>
        </div>
      </div>
    </div>
  </div>
  <!-- À qui est destiné cette formation? -->
  <div style="margin-top: 50px">
    <h3>À qui est destiné cette formation?</h3>
    <div class="paragraphDescription">
      <p>
        La formation tout au long de la vie vous concerne tous: collégiens, lycéens, étudiants, salariés, demandeurs d’emploi.
      </p>
    </div>
  </div>
  <!-- Formation professionnelle-->
  <div class="professionalTraining" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="50px">
    <div fxFlex.gt-sm="50" fxHide.lt-md>
      <img src="/assets/illustrations/training.png" alt="training">
    </div>
    <div style="width: 100%">
      <mat-card style="width: 100%">
        <h4>Formation Professionnelle</h4>
        <p>
          La formation professionnelle est un outil majeur à la disposition des salariés qui leur permet de se former tout au long de leur parcours professionnel pour :
        </p>
        <mat-list>
          <mat-list-item><mat-icon>done_outline</mat-icon>Acquérir ou développer leurs compétences</mat-list-item>
          <mat-list-item><mat-icon>done_outline</mat-icon> S’adapter aux évolutions de poste</mat-list-item>
          <mat-list-item><mat-icon>done_outline</mat-icon>Se réorienter</mat-list-item>
          <mat-list-item><mat-icon>done_outline</mat-icon>Se former pour entreprendre </mat-list-item>
          <mat-list-item class="itemSeparator"><mat-icon>done_outline</mat-icon>Faire reconnaître leurs compétences, leurs connaissances et leurs aptitudes acquises dans l’exercice de votre métier</mat-list-item>
        </mat-list>
      </mat-card>
    </div>
  </div>
  <!-- Comment nous vous accompagnons -->
  <div style="margin-top: 20px">
    <h3>Comment nous vous accompagnons</h3>
    <div class="paragraphDescription">
      <p style="max-width: 100%!important;">
        Direct du droit vous accompagne donc, totalement en interne, au suivi de formations programmées, préparées et mises sur pied par une équipe professionnelle et disposant des acquis nécessaires à la réalisation de celles-ci mais aussi et surtout un réel atout pédagogique dans la façon de constituer ces formations en adoptant des techniques bien plus ludiques que les formations générales.
      </p>
    </div>
  </div>
  <!-- Footer -->
  <div class="footerContainer">
    <span>© Tous les droits sont résérvés</span>
  </div>
</div>
