<div class="appContainer">
  <div>
    <app-nav></app-nav>
  </div>
  <!-- Jumbotron -->
  <div class="jumbotron" >
    <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="none center">
      <!-- Jumbotron Description -->
      <div>
        <h1>Booster Votre Startup</h1>
        <div class="jumbotronDescription">
          <p>lorem ipsum dolor sit amet, consectetur adipiscing elit. maecenas accumsan eget dolor id hendrerit. nullam eleifend  justo, a mattis augue bibendum non. </p>
        </div>
        <div style="margin-top: 5px">
          <button mat-raised-button class="customizedButton mat-elevation-z4" routerLink="/enterprise">
            Commencer
            <mat-icon>arrow_forward</mat-icon>
          </button>
        </div>
      </div>
      <!-- Card Container -->
      <div  fxHide.lt-md style="padding-left: 8vw">
        <div fxLayout.gt-sm="row" fxLayout.lt-md="column" fxLayoutGap="30px" class="cardContainer">
          <!-- Firs tCard -->
          <div style="z-index: 1; padding-top: 40px">
            <div class="cardStyling">
              <div class="cardHeader">
                <span class="mat-elevation-z4">
                    <img src="/assets/icons/income.png"  alt="revenue">
                </span>
                <svg width="100%" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 280 150.96"><defs><style>.cls-1{fill:#FEF2F3;}</style></defs><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><path class="cls-1" d="M0,151H280V12c-.09-.62-1.29-8.46-7-11-4.27-1.9-10.17-.45-11.56-.11L261,1a72.94,72.94,0,0,0-6.93,2.47s-.54.25-1.07.53C252.86,4,34,118.54,12,131c-1.94,1.09-8.42,4.85-10,11H2C1.33,145,.67,148,0,151Z"/></g></g></svg>
              </div>
              <div class="cardContent">
                <div class="cardText">
                  <h5>INFOGRAFICS</h5>
                  <p>
                    Lorem Ipsum Dolor Sit Amet, Consectetur Adipiscing Elit.
                    Maecenas Accumsan Eget Dolor Id Hendrerit
                  </p>
                </div>
                <div class="illustrationContainer">
                  <div class="mat-elevation-z3">
                    <img src="/assets/illustrations/infografics.png" alt="Analitiques">
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- Second Card -->
          <div style="z-index: 1; padding-top: 10px">
            <div class="cardStyling">
              <div class="cardHeader">
                <span class="mat-elevation-z4">
                  <img src="/assets/icons/statistics.png" alt="revenue">
                </span>
                <svg width="100%" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 280 150.96"><defs><style>.cls-1{fill:#FEF2F3;}</style></defs><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><path class="cls-1" d="M0,151H280V12c-.09-.62-1.29-8.46-7-11-4.27-1.9-10.17-.45-11.56-.11L261,1a72.94,72.94,0,0,0-6.93,2.47s-.54.25-1.07.53C252.86,4,34,118.54,12,131c-1.94,1.09-8.42,4.85-10,11H2C1.33,145,.67,148,0,151Z"/></g></g></svg>
              </div>
              <div class="cardContent">
                <div class="cardText">
                  <h5>analytics</h5>
                  <p>
                    Lorem Ipsum Dolor Sit Amet, Consectetur Adipiscing Elit.
                    Maecenas Accumsan Eget Dolor Id Hendrerit
                  </p>
                </div>
                <div class="illustrationContainer">
                  <div class="mat-elevation-z3">
                    <img src="/assets/illustrations/analytic.png" alt="Statistiques">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<div  class="partnerContainer" fxHide.lt-md>
  <div class="partnerTitle">
    <h3>Nos Partenaires</h3>
  </div>
  <div>
    <div fxLayout.gt-sm="row" fxLayout.lt-md="column" fxLayoutAlign="space-around center" fxLayoutGap="5px">
      <div>
        <img src="/assets/partner-logos/bfm.png"  width="140" height="45"  alt="LLA">
      </div>
      <div>
        <img src="/assets/partner-logos/societe-generale.png" width="140" height="40" alt="LLA">
      </div>
      <div>
        <img src="/assets/partner-logos/lla.png" width="66" height="50" alt="LLA">
      </div>
      <div>
        <img src="assets/partner-logos/bnp.png" width="140" height="40" alt="LLA">
      </div>
      <div >
        <img src="/assets/partner-logos/cnfpi.png" width="160" height="50" alt="LLA" >
      </div>
    </div>
  </div>
</div>

<!-- Website Description -->
<div class="websiteDescription">
  <!-- How it works -->
  <div class="howItWork">
    <div class="howItWorkTitle">
      <h3 class="sectionTitle">Comment ça fonctionne?</h3>
    </div>
    <div class="howItWorkDetails">
      <div fxLayout.gt-sm="row" fxLayout.lt-md="column" fxLayoutAlign="space-between stretch">
        <!-- Fill File Container -->
        <div>
          <div class="iconContainer mat-elevation-z4">
            <img src="/assets/icons/fill.png" alt="Remplir Le Formulaire">
          </div>
          <h4>Remplissez Votre Formulaire</h4>
          <div>
            <p>Vivamus fermentum vehicula elit, nec egestas sem interdum aliquam. Aenean malesuada nisi at nibh molestie,</p>
          </div>
        </div>
        <!-- Separator -->
        <div fxHide.lt-md class="pointsSeparatorContainer">
          <div style="text-align: center">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
        <!-- Verify File Container -->
        <div>
          <div class="iconContainer mat-elevation-z4">
            <img src="/assets/icons/documents.png" alt="Remplir Le Formulaire">
          </div>
          <h4>Verifiez Vos Informations</h4>
          <div>
            <p>Vivamus fermentum vehicula elit, nec egestas sem interdum aliquam. Aenean malesuada nisi at nibh molestie,</p>
          </div>
        </div>
        <!-- Separator -->
        <div fxHide.lt-md class="pointsSeparatorContainer">
          <div style="text-align:center">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
        <!-- DownloadFile Container -->
        <div>
          <div class="iconContainer mat-elevation-z4">
            <img src="/assets/icons/file.png" alt="Remplir Le Formulaire">
          </div>
          <h4>Télechargez Votre Formulaire</h4>
          <div>
            <p>Vivamus fermentum vehicula elit, nec egestas sem interdum aliquam. Aenean malesuada nisi at nibh molestie,</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Our specialities --->
  <diV class="specialities" id="specialites">
    <h3 class="sectionTitle">NOS SPÉCIALITÉS</h3>
    <div fxLayout.gt-sm="row" fxLayout.lt-md="column" fxLayoutAlign="space-between center" fxLayoutGap="30px" class="cardContainer">
      <mat-card>
        <h5>PRÉVISIONNEL</h5>
        <div>
          <p>
            Le budget prévisionnel est le reflet de la santé financière de votre entreprise.
            Son objectif est de vérifier la viabilité, la performance et la rentabilité de votre projet à partir de chiffres réels et projetés.
          </p>
        </div>
      </mat-card>
      <mat-card>
        <h5 style="color: #FF6584">STATUT JURIDIQUE</h5>
        <div>
          <p>
            Le budget prévisionnel est le reflet de la santé financière de votre entreprise.
            Son objectif est de vérifier la viabilité, la performance et la rentabilité de votre projet à partir de chiffres réels et projetés.
          </p>
        </div>
      </mat-card>
      <mat-card>
        <h5>FINANCEMENT</h5>
        <div>
          <p>
            Le budget prévisionnel est le reflet de la santé financière de votre entreprise.
            Son objectif est de vérifier la viabilité, la performance et la rentabilité de votre projet à partir de chiffres réels et projetés.
          </p>
        </div>
      </mat-card>
    </div>

    <div fxLayout.gt-sm="row" fxLayout.lt-md="column" fxLayoutAlign="space-between center" fxLayoutGap="30px" class="cardContainer">
      <mat-card>
        <h5>SITE INTERNET</h5>
        <div>
          <p>
            Le budget prévisionnel est le reflet de la santé financière de votre entreprise.
            Son objectif est de vérifier la viabilité, la performance et la rentabilité de votre projet à partir de chiffres réels et projetés.
          </p>
        </div>
      </mat-card>
      <mat-card>
        <h5 style="color: #FF6584">ÉTUDE DE MARCHÉ</h5>
        <div>
          <p>
            Le budget prévisionnel est le reflet de la santé financière de votre entreprise.
            Son objectif est de vérifier la viabilité, la performance et la rentabilité de votre projet à partir de chiffres réels et projetés.
          </p>
        </div>
      </mat-card>
      <mat-card>
        <h5>STRATÉGIE MARKETING</h5>
        <div>
          <p>
            Le budget prévisionnel est le reflet de la santé financière de votre entreprise.
            Son objectif est de vérifier la viabilité, la performance et la rentabilité de votre projet à partir de chiffres réels et projetés.
          </p>
        </div>
      </mat-card>
    </div>
  </diV>
  <!-- Who We Are Section -->
  <div class="whoWeAreSection" id="notreVision">
    <h3 class="sectionTitle">QUI SOMMES NOUS?</h3>
    <div class="videoContainer">
      <mat-card>
        <vg-player style="border-radius: 20px">
          <vg-overlay-play></vg-overlay-play>
          <vg-buffering></vg-buffering>

          <vg-scrub-bar>
            <vg-scrub-bar-current-time></vg-scrub-bar-current-time>
            <vg-scrub-bar-buffering-time></vg-scrub-bar-buffering-time>
          </vg-scrub-bar>

          <vg-controls>
            <vg-play-pause></vg-play-pause>


            <vg-time-display vgProperty="current" vgFormat="mm:ss"></vg-time-display>

            <vg-scrub-bar style="pointer-events: none;"></vg-scrub-bar>

            <vg-time-display vgProperty="left" vgFormat="mm:ss"></vg-time-display>

            <vg-mute></vg-mute>
            <vg-volume></vg-volume>

            <vg-fullscreen></vg-fullscreen>
          </vg-controls>

          <video  [vgMedia]="$any(media)" #media id="singleVideo" preload="auto" crossorigin>
            <source src="/assets/videos/formation.mp4" type="video/mp4">
          </video>
        </vg-player>
      </mat-card>
    </div>
  </div>
  <!-- Conatct Us -->
  <div class="contactUsSection" id="contact">
    <h3 class="sectionTitle">CONTACTEZ NOUS</h3>
    <div fxLayout.gt-sm="row" fxLayout.lt-md="column" fxLayoutAlign="space-around center" style="margin-top: 40px" fxLayoutGap="20px">
      <div fxFlex.gt-sm="50" class="contactUsForm" style="margin-left: auto; margin-right: auto; width: 100%">
        <mat-card style="margin-left: auto; margin-right: auto; border-radius: 20px; padding: 30px" class="formContainer">
          <!-- Name & Last Name -->
          <div fxLayout.gt-md="row" fxLayout.lt-lg="column" fxLayoutGap.gt-md="10px">
            <mat-form-field appearance="outline" color="accent">
              <mat-label>Nom</mat-label>
              <input matInput placeholder="Mark...">
              <mat-icon matSuffix color="accent">account_circle</mat-icon>
            </mat-form-field>
            <mat-form-field appearance="outline" color="accent">
              <mat-label>Prénom</mat-label>
              <input matInput placeholder="Jacob...">
              <mat-icon matSuffix color="accent">account_circle</mat-icon>
            </mat-form-field>
          </div>
          <!-- Phone number -->
          <div>
            <mat-form-field appearance="outline" color="accent">
              <mat-label>Numéro de téléphone</mat-label>
              <input matInput placeholder="+49 218 *** *** ***">
              <mat-icon matSuffix color="accent">phone</mat-icon>
            </mat-form-field>
          </div>
          <!-- Email & Object -->
          <div fxLayout.gt-md="row" fxLayout.lt-lg="column" fxLayoutGap.gt-md="10px">
            <mat-form-field appearance="outline" color="accent">
              <mat-label>Email</mat-label>
              <input matInput placeholder="example@example.com">
              <mat-icon matSuffix color="accent">mail</mat-icon>
            </mat-form-field>
            <mat-form-field appearance="outline" color="accent">
              <mat-label>Object</mat-label>
              <input matInput placeholder="Création d'entreprise">
              <mat-icon matSuffix color="accent">emoji_objects</mat-icon>
            </mat-form-field>
          </div>
          <!-- Email Object -->
          <div>
            <mat-form-field appearance="outline" color="accent">
              <mat-label>Description...</mat-label>
              <textarea matInput rows="6"></textarea>
            </mat-form-field>
          </div>
          <!-- Submit button -->
          <div style="text-align: center; margin-top: 10px;">
            <button mat-raised-button color="accent"  class="submitButton">
              Envoyer
              <mat-icon>send</mat-icon>
            </button>
          </div>
        </mat-card>
      </div>
      <div style="margin-right: auto; margin-left: auto;" fxHide.lt-md>
        <img src="/assets/illustrations/message.png" alt="message" class="img-fluid" style="border-radius: 30px">
      </div>
    </div>
  </div>
  <!-- Footer -->
  <div class="footerContainer">
    <span>© Tous les droits sont résérvés</span>
  </div>
</div>
