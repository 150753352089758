export const rcsData = [
  'GREFFE',
  'VIENNE',
  'NEVERS',
  'MENDE',
  'SAINT-QUENTIN',
  'SAINTES',
  'TOURS',
  'SAINT-NAZAIRE',
  'LEMANS',
  'SEDAN',
  'MARSEILLE',
  'LISIEUX',
  'EVREUX',
  'SAINTMALO',
  'GRENOBLE',
  'COUTANCES',
  'VALENCIENNES',
  'ARRAS',
  'LYON',
  'EVRY',
  'FREJUS',
  'LIMOGES',
  'THIONVILLE',
  'CHERBOURG',
  'ALBI',
  'CRETEIL',
  'SAINT-DENIS-DE-LA-REUNION',
  'TARASCON',
  'MULHOUSE',
  'NANCY',
  'VERSAILLES',
  'BERNAY',
  'LAROCHELLE',
  'BREST',
  'SARREGUEMINES',
  'CHAMBERY',
  'BLOIS',
  'LILLEMETROPOLE',
  'MANOSQUE',
  'NIMES',
  'BELFORT',
  'NANTERRE',
  'ROANNE',
  'SALON-DE-PROVENCE',
  'RENNES',
  'BRIEY',
  'LORIENT',
  'CUSSET',
  'NARBONNE',
  'CAEN',
  'DIJON',
  'PERIGUEUX',
  'QUIMPER',
  'CHATEAUROUX',
  'NANTES',
  'CHALON-SUR-SAONE',
  'CAYENNE',
  'ROMANS',
  'BAYONNE',
  'SAVERNE',
  'BESANCON',
  'BOBIGNY',
  'SAINT-BRIEUC',
  'ANNECY',
  'AURILLAC',
  'ALENCON',
  'PERPIGNAN',
  'BOURG-EN-BRESSE',
  'ANTIBES',
  'BRIVE',
  'BORDEAUX',
  'MONTPELLIER',
  'ANGERS',
  'CHAUMONT',
  'LAVAL',
  'BAR-LE-DUC',
  'VILLEFRANCHE-TARARE',
  'MONTAUBAN',
  'AUXERRE',
  'SENS',
  'NERAC',
  'METZ',
  'STRASBOURG',
  'COLMAR',
  'BASSE-TERRE',
  'FORT-DE-FRANCE',
  'LONS-LE-SAUNIER',
  'CASTRES',
  'POITIERS',
  'PAU',
  'DIEPPE',
  'LAROCHE-SUR-YON',
  'EPINAL',
  'GRASSE',
  'TARBES',
  'THONON-LES-BAINS',
  'LEPUY-EN-VELAY',
  'SAINT-PIERRE-DE-LA-',
  'REUNION',
  'NICE',
  'AUBENAS',
  'BEZIERS',
  'DUNKERQUE',
  'GAP',
  'RODEZ',
  'BERGERAC',
  'MONT-DE-MARSAN',
  'FOIX',
  'ANGOULEME',
  'AJACCIO',
  'TOULOUSE',
  'LIBOURNE',
  'DAX',
  'BEAUVAIS',
  'COMPIEGNE',
  'BOULOGNE-SUR-MER',
  'MACON',
  'PARIS',
  'LEHAVRE',
  'AVIGNON',
  'BOURGES',
  'ORLEANS',
  'POINTE-A-PITRE',
  'MAMOUDZOU',
  'VANNES',
  'AMIENS',
  'NIORT',
  'GUERET',
  'DOUAI',
  'CANNES',
  'TROYES',
  'AUCH',
  'AGEN',
  'REIMS',
  'MELUN',
  'SOISSONS',
  'MONTLUCON',
  'CARCASSONNE',
  'AIX-EN-PROVENCE',
  'BASTIA',
  'CHARTRES',
  'SAINT-ETIENNE',
  'CAHORS',
  'CHALONS-EN-CHAMPAGNE',
  'ROUEN',
  'MEAUX',
  'DRAGUIGNAN',
  'VESOUL',
  'PONTOISE',
  'TOULON',
  'CLERMONT-FERRAND'
];
