

<mat-horizontal-stepper linear #stepper color="accent">
  <mat-step>
    <ng-template matStepLabel >Projet</ng-template>
    <div class="secondStepper">
      <mat-horizontal-stepper linear #secondStepper color="accent">
        <!-- When you want to creat your Enterprise -->
        <mat-step>
          <ng-template matStepLabel>Date de création</ng-template>
          <h3>Dans quel délai voulez-vous créer votre société?</h3>
          <p>
            Ces informations nous permettront de vous aider au cours de votre processus de création.
          </p>
          <div style="min-height: 250px;"
               fxLayout="row wrap"
               fxLayout.lt-sm="column"
               fxLayoutGap="15px"
               fxLayoutAlign="flex-start">
            <ng-container *ngFor="let cardDuration of durationsOfCreation">
              <div [ngClass]="{'active': cardDuration.active}"
                   fxFlex="0 1 calc(50% - 15px)"
                   fxFlex.lt-sm="100%"
                   class="activityCard"
                   (click)="clickDurationCard(cardDuration)"
              >
                {{cardDuration.name}}
              </div>
            </ng-container>
          </div>
          <div style="text-align: right;">
            <button mat-raised-button matStepperNext color="accent">Suivant</button>
          </div>
        </mat-step>
        <!-- Enterprise Activity -->
        <mat-step >
          <ng-template matStepLabel>Activité</ng-template>
          <h3>Quelle sera l'activité de votre société ?</h3>
          <p>
            L'activité indiquée servira de base à la rédaction de l'objet social.
          </p>
          <!-- Activity Card -->
          <div style="min-height: 250px"
               fxLayout="row wrap"
               fxLayout.lt-sm="column"
               fxLayoutGap="15px"
               fxLayoutAlign="flex-start">
            <ng-container *ngFor="let activity of companyActivitiesExample">
              <div [ngClass]="{'active': activity.active}"
                   fxFlex="0 1 calc(33.3% - 15px)"
                   fxFlex.lt-md="0 1 calc(50% - 15px)"
                   fxFlex.lt-sm="100%"
                   class="activityCard"
                   (click)="clickedCard(activity)"
              >
                {{activity.name}}
              </div>
            </ng-container>
          </div>
          <!-- Other Activity -->
          <ng-container *ngIf="otherActivity">
            <mat-form-field appearance="outline" color="accent" style="width: 98%; margin-bottom: -0.5rem;">
              <mat-label>Quelle est votre activité ?</mat-label>
              <input matInput [(ngModel)]="otherActivityDef" placeholder="Ex: Restauration, VTC, WebDesigner ...">
              <mat-icon matPrefix>search</mat-icon>
            </mat-form-field>
          </ng-container>
          <ng-container *ngIf="checkIfSelected()">
            <h3 style="padding-top: 15px">Quelle est votre objet sociale?</h3>
            <mat-form-field appearance="outline" color="accent">
              <mat-label>Objet sociale</mat-label>
              <input matInput [(ngModel)]="enterprise.socialObject">
              <mat-icon matSuffix>emoji_objects</mat-icon>
            </mat-form-field>
          </ng-container>
          <div style="text-align: right">
            <button mat-raised-button matStepperPrevious color="primary" style="margin-right: 15px">Précédent</button>
            <button mat-raised-button matStepperNext color="accent">Suivant</button>
          </div>
        </mat-step>
        <!-- The associates  -->
        <mat-step>
          <ng-template matStepLabel>Les associés</ng-template>
          <h3>Qui sont les associés de votre société ?</h3>
          <p>
            Le 1er associé sera notre point de contact pour vous accompagner dans votre création.
          </p>
          <div class="formField">
            <!-- AssociateE List-->
            <div *ngFor="let item of associates; let i = index">
              <div style="padding-bottom: 7px">
                <span class="formFiledTitle">Associé {{i + 1}}:</span>
                <mat-radio-group [(ngModel)]="item.gender" aria-label="Select an option" style="margin-left: 25px">
                  <mat-radio-button value="H">M.</mat-radio-button>
                  <mat-radio-button value="F">Mme</mat-radio-button>
                </mat-radio-group>
              </div>
              <div fxLayout.gt-sm="row" fxLayout.lt-md="column" fxLayoutGap.gt-sm="10px">
                <mat-form-field appearance="outline" color="accent">
                  <mat-label>Nom</mat-label>
                  <input matInput [(ngModel)]="item.name" (keyup)="fillAssociates()" required>
                  <mat-icon matSuffix>account_circle</mat-icon>
                </mat-form-field>
                <mat-form-field appearance="outline" color="accent">
                  <mat-label>Prénom</mat-label>
                  <input matInput [(ngModel)]="item.lastName" (keyup)="fillAssociates()" required>
                  <mat-icon matSuffix>account_circle</mat-icon>
                </mat-form-field>
              </div>
              <ng-container *ngIf="i === 0">
                <div>
                  <mat-form-field appearance="outline" color="accent">
                    <mat-label>Adresse mail</mat-label>
                    <input matInput placeholder="exemple@exemple.com" required>
                    <mat-icon matSuffix>email</mat-icon>
                  </mat-form-field>
                </div>
              </ng-container>
              <ng-container *ngIf="i === 0">
                <div>
                  <mat-form-field appearance="outline" color="accent">
                    <mat-label>Téléphone</mat-label>
                    <input matInput placeholder="+49 359 875 *** ***" required>
                    <mat-icon matSuffix>phone</mat-icon>
                  </mat-form-field>
                </div>
              </ng-container>
            </div>
            <!-- Add AssociateE Button-->
            <div>
              <button mat-flat-button (click)="addAssociate()">
                <mat-icon color="primary">add</mat-icon>
                <span style="color: #3239BB">Ajouter un associé</span>
              </button>
              <button *ngIf="associates.length > 1" mat-flat-button (click)="associates.splice(-1,1)">
                <mat-icon color="accent">remove</mat-icon>
                <span style="color: #FF6584">Supprimer un associé</span>
              </button>
            </div>
          </div>
          <div style="text-align: right;">
            <button mat-raised-button matStepperPrevious color="primary" style="margin-right: 15px">Précédent</button>
            <button mat-raised-button (click)="goForward(stepper)" [disabled]="nextButton" color="accent">Suivant</button>
          </div>
        </mat-step>
      </mat-horizontal-stepper>
    </div>
  </mat-step>








  <mat-step>
    <ng-template matStepLabel>Informations</ng-template>
    <div class="secondStepper">
      <mat-horizontal-stepper linear #thirdStepper color="accent">
        <!-- Who'll be the president -->
        <mat-step>
          <ng-template matStepLabel>Président</ng-template>
          <h3> Qui sera le président ?</h3>
          <p>Choisissez le président dans la liste des associés</p>
          <div style="min-height: 250px;"
               fxLayout="row wrap"
               fxLayout.lt-sm="column"
               fxLayoutGap="15px"
               fxLayoutAlign="flex-start">
            <ng-container *ngFor="let item of associates">
              <div [ngClass]="{'active': item.active}"
                   fxFlex="0 1 calc(33.33% - 15px)"
                   fxFlex.lt-md="0 1 calc(50% - 15px)"
                   fxFlex.lt-sm="100%"
                   class="activityCard"
                   (click)="presidentCardStyle(item)"
              >
                {{item.name}} {{item.lastName}}
              </div>
            </ng-container>
            <div [ngClass]="{'active': nonAssociatePresident}"
                 fxFlex="0 1 calc(33.33% - 15px)"
                 fxFlex.lt-md="0 1 calc(50% - 15px)"
                 fxFlex.lt-sm="100%"
                 class="activityCard"
                 (click)="anotherPresidentStyle()"
            >
              Autre
            </div>
          </div>
          <ng-container *ngIf="nonAssociatePresident">
            <div fxLayout.gt-sm="row" fxLayout.lt-md="column" fxLayoutGap.gt-sm="8px" fxLayoutAlign="space-between">
              <mat-form-field appearance="outline" color="accent">
                <mat-label>Nom</mat-label>
                <input matInput required [(ngModel)]="enterprise.anotherPresident.lastName">
                <mat-icon matSuffix>account_circle</mat-icon>
              </mat-form-field>
              <mat-form-field appearance="outline" color="accent">
                <mat-label>Prénom</mat-label>
                <input matInput required [(ngModel)]="enterprise.anotherPresident.firstName">
                <mat-icon matSuffix>account_circle</mat-icon>
              </mat-form-field>
              <mat-form-field appearance="outline" color="accent">
                <mat-label>Email</mat-label>
                <input matInput required [(ngModel)]="enterprise.anotherPresident.email">
                <mat-icon matSuffix>mail</mat-icon>
              </mat-form-field>
            </div>
          </ng-container>
          <!-- Mat Step Buttons -->
          <div style="text-align: right;">
            <button mat-raised-button (click)="goBackward(stepper)" color="primary" style="margin-right: 15px">Précédent</button>
            <button mat-raised-button matStepperNext color="accent">Suivant</button>
          </div>
        </mat-step>
        <!-- General Director -->
        <mat-step>
          <ng-template matStepLabel>Directeur Général</ng-template>
          <h3>Souhaitez-vous nommer un directeur général ?</h3>
          <p>
            Le directeur général dirige la société aux côtés du président.
          </p>
          <div style="min-height: 250px;"
               fxLayout="row wrap"
               fxLayout.lt-sm="column"
               fxLayoutGap="15px"
               fxLayoutAlign="flex-start">
            <div [ngClass]="{'active': isGeneralDirector === 'yes'}"
                 fxFlex="0 1 calc(50% - 15px)"
                 fxFlex.lt-md="0 1 calc(50% - 15px)"
                 fxFlex.lt-sm="100%"
                 class="activityCard"
                 (click)="isGeneralDirector = 'yes'"
            >
              Oui
            </div>
            <div [ngClass]="{'active': isGeneralDirector === 'no'}"
                 fxFlex="0 1 calc(50% - 15px)"
                 fxFlex.lt-md="0 1 calc(50% - 15px)"
                 fxFlex.lt-sm="100%"
                 class="activityCard"
                 (click)="isGeneralDirector = 'no'"
            >
              <span>Non </span> <span style="font-weight: 400; font-size: 0.875rem;padding-left: 5px"> 65% des utilisateurs</span>
            </div>
          </div>
          <ng-container *ngIf="isGeneralDirector ==='yes'">
            <h3 style="padding-top: 25px">Qui sera directeur général ?</h3>
            <div style="min-height: 250px;"
                 fxLayout="row wrap"
                 fxLayout.lt-sm="column"
                 fxLayoutGap="15px"
                 fxLayoutAlign="flex-start">
              <ng-container *ngFor="let associate of associates">
                <div [ngClass]="{'active': associate.generalDirector}"
                     fxFlex="0 1 calc(33.33% - 15px)"
                     fxFlex.lt-md="0 1 calc(50% - 15px)"
                     fxFlex.lt-sm="100%"
                     class="activityCard"
                     (click)="generalDirector(associate)"
                >
                  {{associate.name}} {{associate.lastName}}
                </div>
              </ng-container>
              <div [ngClass]="{'active': isAnotherGeneralDirector}"
                   fxFlex="0 1 calc(33.33% - 15px)"
                   fxFlex.lt-md="0 1 calc(50% - 15px)"
                   fxFlex.lt-sm="100%"
                   class="activityCard"
                   (click)="generalDirector(false)"
              >
                Autre
              </div>
            </div>
            <!-- Director Type-->
            <ng-container *ngIf="isAnotherGeneralDirector">
              <span style="padding-top: 30px; font-weight: 500">Qui est le directeur général ?</span>
              <div fxLayout.gt-sm="row" fxLayout.lt-md="column" fxLayoutGap.gt-sm="8px" fxLayoutAlign="space-between ">
                <mat-form-field appearance="outline" color="accent">
                  <mat-label>Nom</mat-label>
                  <input matInput [(ngModel)]="enterprise.generalDirector.lastName">
                  <mat-icon matSuffix>account_circle</mat-icon>
                </mat-form-field>
                <mat-form-field appearance="outline" color="accent">
                  <mat-label>Prénom</mat-label>
                  <input matInput [(ngModel)]="enterprise.generalDirector.firstName">
                  <mat-icon matSuffix>account_circle</mat-icon>
                </mat-form-field>
                <mat-form-field appearance="outline" color="accent">
                  <mat-label>Email</mat-label>
                  <input matInput >
                  <mat-icon matSuffix [(ngModel)]="enterprise.generalDirector.email">mail</mat-icon>
                </mat-form-field>
              </div>
            </ng-container>
          </ng-container>

          <div style="text-align: right;">
            <button mat-raised-button matStepperPrevious color="primary" style="margin-right: 15px">Précédent</button>
            <button mat-raised-button matStepperNext color="accent">Suivant</button>
          </div>
        </mat-step>
        <!-- Capital -->
        <mat-step>
          <h3>Quel est le montant du capital de votre société ?</h3>
          <p>La majorité des entrepreneurs déposent entre 100€ et 10 000€ pour créer une SAS (montant minimum de 1€).</p>
          <ng-container *ngIf="associates.length > 0">
            <div *ngFor="let associate of associates; let i = index"
                 fxLayout.gt-sm="row"
                 fxLayout.lt-md="column"
                 fxLayoutAlign.gt-sm=" center"
                 fxLayoutGap.gt-sm="10px">
              <div fxFlex.gt-sm="50">
                <span style="font-weight: bold">Associé {{i + 1}}: </span>
                <span style="text-transform: capitalize; padding-left: 5px"> {{associate.lastName}} {{associate.name}}</span>
              </div>
              <mat-form-field appearance="outline" color="accent" fxFlex.gt-sm="25">
                <input type="number" matInput required [(ngModel)]="associate.depositMoney" (keyup)="calcPercentage()">
                <mat-icon matSuffix>euro_symbol</mat-icon>
              </mat-form-field>
              <mat-form-field appearance="outline" color="accent" fxFlex.gt-sm="25">
                <mat-label>Pourcentage</mat-label>
                <input matInput disabled [(ngModel)]="associate.percentage">
                <mat-icon matSuffix>%</mat-icon>
              </mat-form-field>
            </div>
            <div style="text-transform: uppercase; font-size: 0.875rem; font-weight: 500; padding-top: 20px"
                 fxLayout.gt-sm="row"
                 fxLayout.lt-md="column"
                 fxLayoutGap.gt-sm="10px"
                 fxLayoutAlign=" center">
              <div fxFlex="50">
                <span >Montant du capital</span>
              </div>
              <div fxFlex="50">
                {{calcSum() || 0}} €
              </div>
            </div>
          </ng-container>
          <div style="text-align: right;">
            <button mat-raised-button matStepperPrevious color="primary" style="margin-right: 15px">Précédent</button>
            <button mat-raised-button matStepperNext color="accent">Suivant</button>
          </div>
        </mat-step>
        <!-- Company premises -->
        <mat-step>
          <h3>Où sera le siège social de votre société ?</h3>
          <p>Le siège correspond à l'adresse administrative. Vous y recevrez vos courriers officiels.</p>
          <div fxLayout.gt-sm="row wrap" fxLayout.lt-md="column" fxLayoutGap.gt-sm="15px" fxLayoutGap.lt-md="10px">
            <div *ngFor="let item of companyPremise"
                 fxFlex="0 1 calc(33.33% - 15px)"
                 class="activityCard"
                 [ngClass]="{'active': item.active}"
                 (click)="chooseCompanyPremise(item)">
              <span style="font-size: 0.825rem; font-weight: normal; min-height: 55px">{{item.description}}</span>
            </div>
          </div>
          <ng-container *ngIf="companyAllowedAddress">
            <div style="padding-top: 15px">
              <mat-form-field appearance="outline" color="accent">
                <mat-label>Adresse</mat-label>
                <input matInput [(ngModel)]="enterprise.address">
              </mat-form-field>
            </div>
          </ng-container>
          <ng-container *ngIf="isDirectDuDroitPremise">
            <div style="padding-top: 15px">
              <mat-form-field appearance="outline" color="accent">
                <mat-label>Ville</mat-label>
                <mat-select [(ngModel)]="address" (selectionChange)="getTown($event, address)">
                  <mat-option *ngFor="let item of directCity" [value]="item.city">
                    {{item.city}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field appearance="outline" color="accent">
                <mat-label>Cité</mat-label>
                <mat-select [(ngModel)]="townsCity">
                  <mat-option *ngFor="let item of town" [value]="item">
                    {{item}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </ng-container>
          <div style="text-align: right">
            <button mat-raised-button matStepperPrevious color="primary" style="margin-right: 15px">Précédent</button>
            <button mat-raised-button matStepperNext color="accent">Suivant</button>
          </div>
        </mat-step>
        <!-- Enterprise name -->
        <mat-step>
          <h3>Connaissez-vous déjà le nom de votre société ?</h3>
          <mat-form-field appearance="outline" color="accent">
            <mat-label>Nom de votre entreprise</mat-label>
            <input matInput [(ngModel)]="enterprise.name">
            <mat-icon matSuffix>apartment</mat-icon>
          </mat-form-field>
          <div style="text-align: center; padding-top: 15px">
            <span style="font-size: 0.775rem; font-weight: lighter">Vous pourrez modifier le nom plus tard.</span>
          </div>
          <div style="text-align: right">
            <button mat-raised-button matStepperPrevious color="primary" style="margin-right: 15px">Précédent</button>
            <button mat-raised-button matStepperNext color="accent">Suivant</button>
          </div>
        </mat-step>
        <!-- Bank account -->
        <mat-step>
          <h3>Dans quelle banque voulez-vous déposer le capital ?</h3>
          <p>
            Il est obligatoire d'ouvrir un compte bancaire dédié à la société.
          </p>
          <div
            fxLayout="row wrap"
            fxLayout.lt-md="column"
            fxLayoutGap="15px"
            fxLayoutGap.lt-md="10px">
            <div *ngFor="let item of bankDetails"
                 fxFlex="0 1 calc(33.33% - 15px)"
                 class="activityCard"
                 [ngClass]="{'active': item.active}"
                 (click)="chooseBank(item)">
              <span style="font-size: 0.825rem; font-weight: normal; min-height: 55px">{{item.description}}</span>
            </div>
          </div>
          <!-- BNP Customer -->
          <ng-container *ngIf="bnpBank">
            <span class="formFiledTitle">Êtes-vous déjà client BNP Paribas?: </span>
            <mat-radio-group [(ngModel)]="bnpCustomer" class="radioGroup">
              <mat-radio-button value="oui">Oui</mat-radio-button>
              <mat-radio-button value="non">Non</mat-radio-button>
            </mat-radio-group>
            <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap.gt-sm="10px" style="padding-top: 15px">
              <mat-form-field appearance="outline" color="accent">
                <mat-label>Numéro de contact</mat-label>
                <input matInput>
                <mat-icon matSuffix></mat-icon>
              </mat-form-field>
              <mat-form-field appearance="outline" color="accent">
                <mat-label>Choisir une date</mat-label>
                <input matInput [matDatepicker]="picker">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
              </mat-form-field>
              <mat-form-field appearance="outline" color="accent">
                <mat-label>À quelle heure ?</mat-label>
                <mat-select>
                  <mat-option value="morning">
                    9h - 12h
                  </mat-option>
                  <mat-option value="evening">
                    14h - 17h
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </ng-container>
          <div style="text-align: right">
            <button mat-raised-button matStepperPrevious color="primary" style="margin-right: 15px">Précédent</button>
            <button mat-raised-button matStepperNext color="accent">Suivant</button>
          </div>
        </mat-step>
        <!-- RCS -->
        <mat-step>
          <h3>Quel RCS vous convient?</h3>
          <p>
            Ces informations nous permettront de vous aider au cours de votre processus de création.
          </p>
          <mat-form-field appearance="outline" color="accent">
            <mat-label>Choisir RCS</mat-label>
            <mat-select [(ngModel)]="enterprise.RCS">
              <mat-option *ngFor="let item of rcs" [value]="item">
                {{item}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <div style="text-align: right">
            <button mat-raised-button matStepperPrevious color="primary" style="margin-right: 15px">Précédent</button>
            <button mat-raised-button matStepperNext (click)="fillEnterprise()" color="accent">Suivant</button>
          </div>
        </mat-step>
        <!-- Sign -->
        <mat-step>
          <h3>Déclaration de non condamnation et de filiation</h3>
          <p>
            Ces informations nous permettront de vous aider au cours de votre processus de création.
          </p>
          <div fxLayout.gt-sm="row" fxLayout.lt-md="column" fxLayoutGap.gt-sm="15px">
            <mat-form-field appearance="outline" color="accent">
              <mat-label>Nom du signateur</mat-label>
              <input matInput [(ngModel)]="signer.firstName">
              <mat-icon matSuffix>account_circle</mat-icon>
            </mat-form-field>
            <mat-form-field appearance="outline" color="accent">
              <mat-label>Prénom du signateur</mat-label>
              <input matInput [(ngModel)]="signer.lastName">
              <mat-icon matSuffix>account_circle</mat-icon>
            </mat-form-field>
          </div>
          <mat-form-field appearance="outline" color="accent">
            <mat-label>Demeurant</mat-label>
            <input matInput [(ngModel)]="signer.dem">
            <mat-icon matSuffix>account_circle</mat-icon>
          </mat-form-field>
          <div fxLayout.gt-sm="row" fxLayout.lt-md="column" fxLayoutGap.gt-sm="15px">
            <mat-form-field appearance="outline" color="accent">
              <mat-label>Date de naissance</mat-label>
              <input matInput [matDatepicker]="birth" [(ngModel)]="signer.birthDate">
              <mat-datepicker-toggle matSuffix [for]="birth"></mat-datepicker-toggle>
              <mat-datepicker #birth></mat-datepicker>
            </mat-form-field>
            <mat-form-field appearance="outline" color="accent">
              <mat-label>Ville de naissance</mat-label>
              <input matInput [(ngModel)]="signer.city">
              <mat-icon matSuffix>room</mat-icon>
            </mat-form-field>
          </div>
          <h6 style="font-weight: bold; font-size: 0.875rem; margin-top: 10px; margin-bottom: 5px">Autres informations :</h6>
          <div fxLayout.gt-sm="row" fxLayout.lt-md="column" fxLayoutGap.gt-sm="15px">
            <mat-form-field appearance="outline" color="accent">
              <mat-label>Nom du père</mat-label>
              <input matInput [(ngModel)]="signer.fatherFirstName">
              <mat-icon matSuffix>account_circle</mat-icon>
            </mat-form-field>
            <mat-form-field appearance="outline" color="accent">
              <mat-label>Prénom du père</mat-label>
              <input matInput [(ngModel)]="signer.fatherLastName">
              <mat-icon matSuffix>account_circle</mat-icon>
            </mat-form-field>
          </div>
          <div fxLayout.gt-sm="row" fxLayout.lt-md="column" fxLayoutGap.gt-sm="15px">
            <mat-form-field appearance="outline" color="accent">
              <mat-label>Nom de la mère</mat-label>
              <input matInput [(ngModel)]="signer.motherFirstName">
              <mat-icon matSuffix>account_circle</mat-icon>
            </mat-form-field>
            <mat-form-field appearance="outline" color="accent">
              <mat-label>Prénom de la mère</mat-label>
              <input matInput [(ngModel)]="signer.motherLastName">
              <mat-icon matSuffix>account_circle</mat-icon>
            </mat-form-field>
          </div>
          <div style="text-align: right">
            <button mat-raised-button matStepperPrevious color="primary" style="margin-right: 15px">Précédent</button>
            <button mat-raised-button matStepperNext color="accent">Suivant</button>
          </div>
        </mat-step>
        <!-- PDF Generator -->
        <mat-step>
          <button mat-raised-button color="accent" (click)="generatePdf()">Generate</button>
        </mat-step>

      </mat-horizontal-stepper>
    </div>
  </mat-step>
  <mat-step>
    <ng-template matStepLabel>Commande</ng-template>
    <div style="text-align: right">
      <button mat-raised-button matStepperPrevious color="primary" style="margin-right: 15px">Précédent</button>
      <button mat-raised-button matStepperNext color="accent">Suivant</button>
    </div>
  </mat-step>
  <mat-step>
    <ng-template matStepLabel>Dossier Juridique</ng-template>
    <p>You are now done.</p>
    <div>
      <button mat-button matStepperPrevious>Back</button>
      <button mat-button (click)="stepper.reset()">Reset</button>
    </div>
  </mat-step>
  <mat-step>
    <ng-template matStepLabel>Validation</ng-template>
    <p>You are now done.</p>
    <div>
      <button mat-button matStepperPrevious>Back</button>
      <button mat-button (click)="stepper.reset()">Reset</button>
    </div>
  </mat-step>
</mat-horizontal-stepper>
