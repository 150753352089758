export class AssociateE {
  // tslint:disable-next-line:variable-name
  _id!: string;
  gender!: string;
  firstName!: string;
  lastName!: string;
  birthDate!: Date;
  isPresident!: boolean;
  isGeneralDirector!: boolean;
  depositMoney!: number;
  percentage!: string;
  constructor() {
  }
}
