<div class="enterpriseContainer">
  <div class="enterpriseJumbotron">
    <div>
      <app-nav></app-nav>
    </div>
<!--    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1366 82">-->
<!--      <defs><style>.cls-1{fill:#F6F6FA;}</style></defs>-->
<!--      <g id="Layer_2" data-name="Layer 2">-->
<!--        <g id="Layer_1-2" data-name="Layer 1"><path class="cls-1" d="M0,0V82H1366V0A3734.36,3734.36,0,0,1,679,63,3734.22,3734.22,0,0,1,0,0Z"/>-->
<!--        </g>-->
<!--      </g>-->
<!--    </svg>-->
  </div>
  <div class="questionsContainer">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1920 350.62">
      <defs><style>.cls-1{fill:#3239bb;}</style></defs>
      <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_1-2" data-name="Layer 1">
          <path class="cls-1" d="M0,0H1920V270.16a5756.87,5756.87,0,0,1-919.24,80.31A5760,5760,0,0,1,0,270.16Z"/>
        </g>
      </g>
    </svg>
    <mat-card>
      <router-outlet></router-outlet>
    </mat-card>
  </div>
</div>
