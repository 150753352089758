<h3>CRÉER VOTRE ENTREPRISE</h3>
<div>
  <p>
    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
    Suspendisse euismod vitae lacus ac viverra. Sed accumsan imperdiet mauris,
    sit amet rutrum velit auctor id.
  </p>
</div>
<div class="introSplash">
  <div fxLayout.gt-sm="row" fxLayout.lt-md="column" fxLayoutGap="15px" fxLayoutAlign="space-around ">
    <div class="introItem">
      <img src="/assets/icons/document-set.png">
      <h4>Combien de temps dure le questionnaire?</h4>
      <p>
        Les utilisateurs le remplissent en moyenne en 5 à 10 minutes.
      </p>
    </div>
    <div class="introItem">
      <img src="/assets/icons/support.png">
      <h4>Vous avez besoin d'aide?</h4>
      <p>
        Nos experts sont là pour vous aider, par chat ou au 01 87 20 15 15.
      </p>
    </div>
    <div class="introItem">
      <img src="/assets/icons/success.png">
      <h4>On s'occupe de tout pour vous!</h4>
      <p>
        Validation du dossier, démarches administratives, envoi au Greffe et transmission de votre Kbis.
      </p>
    </div>
  </div>
  <div class="startButton">
    <button mat-raised-button color="accent" routerLink="sas">Démarrer la création</button>
  </div>
</div>
