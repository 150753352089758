export class Signer {
  firstName!: string;
  lastName!: string;
  dem!: string;
  birthDate!: Date;
  city!: string;
  fatherFirstName!: string;
  fatherLastName!: string;
  motherFirstName!: string;
  motherLastName!: string;
  constructor() {
  }
}
